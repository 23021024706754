@import 'assets/scss/variable.module.scss';

.btnArea {
  width: auto;
  height: 45px;
  gap: 8px;
  border-radius: 16px;
  padding: 0px 10px;
  font-family: 'Muli-Regular';
  font-size: 14px;
  color: var(--text-gray-200-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  white-space: nowrap;
  @media (min-width: 1024px) {
    padding: 0px 20px;
    background: var(--background-main-color);
    border: 1px solid rgba(var(--main-bg-color-rgb), 0.1)
  }
}

.text {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 24px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.menuArea {
  position: absolute;
  top: 50px;
  right: 0px;
  @media (min-width: 1024px) {
    top: 50px;
  }
  background: rgba(var(--collection-card-bg-rgb), 0.7);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 15px;
  padding: 5px 25px;
  overflow: hidden;
  cursor: pointer;
  z-index: 100;
  outline: none;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1)
}

.menuItem {
  width: 100%;

  transition: all 0.2s;
  transition-timing-function: ease-in-out;

  display: flex;
  flex-direction: column;

  *:first-child {
    padding-top: 8px;
  }
  *:last-child {
    margin-bottom: 8px;
  }
  *:not(:last-child) {
    border-bottom: 1px solid rgb(var(--main-bg-color-rgb), 0.1); /* Change color as needed */
    padding: 0 0 8px 0;
  }
}

.removeBlueHighlight {
  * {
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
  }
}
