@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(#1a1a1a, 0.6);
  position: fixed;
  inset: 0;
}

.modalView {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: auto;
  padding: 24px 28px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: var(--ellipse-report-bg);
  border: 1px solid rgba($text-gray-200-color, 0.1);
}

.shareArtwork {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  color: rgba($text-gray-200-color, 0.75);
  margin-bottom: 30px;
}

.menuItem {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: $text-gray-200-color;
  margin-bottom: 10px;
}

.cancel {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
  margin-top: 20px;
  margin-bottom: 4px;
  text-align: center;
}

.title {
  width: 100%;
  text-align: center;
  font-family: 'Muli-SemiBold';
  font-size: 20px;
  line-height: 25px;
  color: var(--text-gray-200-color);
  margin-bottom: 35px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
}

.checkbox {
  appearance: none;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.8);
  background-color: transparent;
  &:checked {
    border: 2px solid var(--main-icon-color);
    background-color: var(--main-icon-color);
    position: relative;
    &:before {
      content: '';
      background-color: transparent;
      /* position the checkbox */
      position: absolute;
      left: 7px;
      top: 2px;

      /* setting the checkbox */
      /* short arm */
      width: 7px;
      border-bottom: 3px solid var(--stats-search-bg);
      /* long arm */
      height: 15px;
      border-right: 3px solid var(--stats-search-bg);

      /* rotate the mirrored L to make it a checkbox */
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  &:focus {
    outline: none;
  }
  vertical-align: top;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 6px;
}

.checkmark {
  cursor: pointer;
  font-family: 'Muli-Regular';
  flex: 1;
  font-size: 16px;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  letter-spacing: 0;
}