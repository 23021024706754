@import 'assets/scss/variable.module.scss';

.layout {
  width: 100%;
  height: 100%;
  font-weight: 100;
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 0px;
  &:hover {
    @media (min-width: 1024px) {
      padding-top: 0px;
      padding-bottom: 2px;
      height: calc(100% - 1px)
    }
  }
}

.containerCard {
  width: 100%;
  @media (min-width: 640px) {
    width: 100%;
  }
  height: auto;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.hideBtnArea {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 45px;
  height: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  background: rgba(var(--background-main-color), 0.5);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.hideConfirm {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid rgba($text-gray-200-color, 0.1);
  border-radius: 10px;
  @media (min-width: 1024px) {
    border-radius: 10px;
  }
  background: rgba(#1a1a1a, 0.75);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 100;
}

.hideText {
  font-family: 'Muli-Regular';
  font-size: 14px;
  padding: 0px 10px;
  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0px 20px;
  }
  color: $text-gray-200-color;
  text-align: center;
}

.hideCancelButton {
  width: 67px;
  height: 26px;
  border-radius: 6px;
  font-size: 16px;
  @media (min-width: 1024px) {
    border-radius: 8px;
    width: 106px;
    height: 40px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($text-gray-200-color, 0.1);
  background: transparent;
  font-family: 'Muli-SemiBold';

  color: $text-gray-200-color;
  cursor: pointer;
}

.hideButton {
  width: 67px;
  height: 26px;
  border-radius: 6px;
  @media (min-width: 1024px) {
    border-radius: 8px;
    width: 106px;
    height: 40px;
  }
  box-shadow: 0px 3px 6px #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brand-09-color;
  cursor: pointer;
}

.hideButtonText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  color: $background-dark-color;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 359:393 Aspect Ratio */
}

.imageInfoHome {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 23px;
  @media (min-width: 640px) {
    padding: 0px 20px;
  }
  @media (min-width: 768px) {
    padding: 0px 21px;
  }
  background: var(--background-main-color);
}

.hideBtnArea {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#1a1a1a, 0.5);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bottomSection {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 23px;
  @media (min-width: 640px) {
    padding: 0px 20px;
  }
  @media (min-width: 768px) {
    padding: 0px 21px;
  }
  border-top: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--background-main-color);
}

.bottomHover {
  @media (min-width: 1024px) {
    background: rgba(var(--main-bg-color-rgb), 0.05) !important;
  }
}

.priceLabel {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--main-bg-color-rgb), 0.85);
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  background: rgba(var(--main-bg-color-rgb), 0.05);
  overflow: hidden;
}

.imageNft {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
  transition: transform 0.2s;
  max-width: 100%;
  max-height: 100%;
  &:hover {
    @media (min-width: 1024px) {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.playButton {
  position: absolute;
  z-index: 11;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--background-light-color), 0.2);
  backdrop-filter: blur(10px);
}

.nftNameForHome {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 640px) {
    font-size: 16px;
    line-height: 20px;
  }
  margin-top: 0px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.artistNameHome {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
  margin-top: 10px;
  letter-spacing: 0;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceHome {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.bidCount {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 22px;
  color: var(--text-gray-200-color);
  text-align: end;
}

.sol_icon_color {
  * {
    fill: var(--main-icon-color);
  }
}