@import 'assets/scss/variable.module.scss';

.layout {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  @media (min-width: 1024px) {
    height: 100%;
    // margin: 10px;
    max-width: 390px;
  }
  cursor: pointer;
  
  margin-top: 2px;
  margin-bottom: 0px;
  &:hover {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.bottomMask {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  @media (min-width: 1024px) {
    height: 100%;
  }
  background: linear-gradient(to bottom, transparent, #000);
}

.removeVisible {
  position: absolute;
  left: 20px;
  top: 27px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  @media (min-width: 1024px) {
    left: 39px;
    top: 29px;
    width: 52px;
    height: 52px;
    border-radius: 26px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(26, 26, 26, 0.75);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bidContentView {
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  @media (min-width: 640px) {
    height: 100px;
  }
  @media (min-width: 1024px) {
    height: 70px;
  }
  @media (min-width: 1280px) {
    height: 84px;
  }
  @media (min-width: 1536px) {
    height: 94px;
  }
  @media (min-width: 1768px) {
    height: 100px;
  }
}

.bidContainer {
  width: 100%;
  height: 90px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--background-main-color);

  @media (min-width: 640px) {
    height: 100px;
  }
  @media (min-width: 1024px) {
    width: 100%;
    height: 70px;
  }
  @media (min-width: 1280px) {
    width: 100%;
    height: 84px;
  }
  @media (min-width: 1536px) {
    width: 100%;
    height: 94px;
  }
  @media (min-width: 1768px) {
    width: 100%;
    height: 100px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bidGradientBgView {
  position: absolute;
  width: 100%;
  height: 100px;
  background: var(--background-main-color);
}

.nftName {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  margin-right: 6px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dotStyle {
  color: var(--text-gray-200-color);
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 2px;
  font-family: 'Muli-ExtraBold';
  font-size: 12px;
  line-height: 12px;
  @media (min-width: 640px) {
    margin-top: 0px;
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.artistName {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nftCount {
  font-family: 'Muli-Light';
  font-size: 12px;
  line-height: 16px;
  @media (min-width: 640px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: 1024px) {
    font-size: 13px;
    line-height: 16px;
  }
  @media (min-width: 1280px) {
    font-size: 14px;
    line-height: 18px;
  }

  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  position: absolute;
  right: 15px;
  width: 45px;
  height: 45px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 640px) {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  @media (min-width: 768px) {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }
  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  @media (min-width: 1280px) {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  @media (min-width: 1680px) {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }
  // background: linear-gradient(to bottom, #95dbd5 0%, #4b6e6b 80%);
  background: transparent;
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.hideConfirm {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid rgba($text-gray-200-color, 0.1);
  border-radius: 10px;
  background: rgba(#1a1a1a, 0.75);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 10;
}

.hideText {
  font-family: 'Muli-Regular';
  font-size: 14px;
  padding: 0px 10px;
  @media (min-width: 1024px) {
    font-size: 16px;
    padding: 0px 20px;
  }
  color: $text-gray-200-color;
  text-align: center;
}

.hideCancelButton {
  width: 104px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($text-gray-200-color, 0.1);
  background: transparent;
  font-family: 'Muli-SemiBold';

  color: $text-gray-200-color;
  cursor: pointer;
}

.hideButton {
  width: 106px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brand-09-color;
  cursor: pointer;
}

.hideButtonText {
  font-family: 'Muli-Bold';
  font-size: 14px;
  color: $background-dark-color;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}