@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 12px;
  font-family: 'Muli-SemiBold';
  line-height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 18px;
  @media (min-width: 1024px) {
    height: 50px;
    font-size: 18px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media (min-width: 1536px) {
    height: 53px;
    font-size: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }
  font-weight: 100;
  letter-spacing: 0;
  color: var(--gradient-btn-color);

  transition: box-shadow 0.2s;
  transition-timing-function: ease-in-out;
}

.disabledBtn {
  opacity: 0.6 !important;
}

.hoverBtn {
  &:hover {
    @media (min-width: 1024px) {
      background: var(--gradient-btn-hover-bg) !important;
      color: var(--gradient-btn-hover-color) !important;
    }
  }
}