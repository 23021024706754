@import 'assets/scss/variable.module.scss';

.container {
  position: fixed;
  top: 80px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background-color: var(--bg-new-color);
  border-radius: 0px;
  padding: 17px 0px 96px 0px;
  z-index: 1000;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 16px;
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
  padding-top: 20px;
  padding-bottom: 7px;
}

.bottomArea {
  width: 100%;
  height: 96px;
  background-color: var(--background-main-color);
  border-top:1px solid rgba(var(--main-bg-color-rgb), 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.chevronUpIcon {
  color: var(--chevron-up-icon-color);
}

.checkbox {
  appearance: none;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.8);
  background-color: transparent;
  &:checked {
    border: 2px solid var(--main-icon-color);
    background-color: var(--main-icon-color);
    position: relative;
    &:before {
      content: '';
      background-color: transparent;
      /* position the checkbox */
      position: absolute;
      left: 7px;
      top: 2px;

      /* setting the checkbox */
      /* short arm */
      width: 7px;
      border-bottom: 3px solid var(--stats-search-bg);
      /* long arm */
      height: 15px;
      border-right: 3px solid var(--stats-search-bg);

      /* rotate the mirrored L to make it a checkbox */
      transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  &:focus {
    outline: none;
  }
  vertical-align: top;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 6px;
}

.checkmark {
  cursor: pointer;
  font-family: 'Muli-Regular';
  flex: 1;
  font-size: 16px;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  letter-spacing: 0;
}

.priceRange {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  color: var(--text-gray-200-color);
  display: flex;
  gap: 10px;
}

.priceValue {
  font-family: 'Muli-Light';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.price {
  font-family: 'Muli-Light';
  font-size: 14px;
  font-weight: 100;
  line-height: 18px;
  color: rgba(var(--main-bg-color-rgb), 0.8)
}