@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 100%;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.topSection {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.artistName {
  font-family: 'Muli-Light';
  font-size: 14px;
  font-weight: 100;
  margin-top: 7px;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottomSection {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding: 0px 13px;
}

.bottomBtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 20px 23px 20px;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(#1a1a1a, 0.6);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-radius: 10px;
}

.moreBtn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--second-home-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.closeBtn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  background: var(--bg-242424-EFF0F605);
  position: absolute;
  top: 22px;
  right: 25px;
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 100%;
  cursor: pointer;
}

.imageInfo {
  width: 100%;
  flex: wrap;
  justify-content: center;
  align-items: center;
  padding: 7px 12px 12px 12px;
  @media (min-width: 640px) {
    padding: 15px 20px;
  }
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.nftName {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category {
  font-family: 'Muli-Bold';
  font-size: 8px;
  line-height: 8px;
  @media (min-width: 640px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 768px) {
    font-size: 11px;
    line-height: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: 1280px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: 1536px) {
    font-size: 16px;
    line-height: 20px;
  }

  color: var(--text-gray-200-color);
  border-radius: 16px;
  border-width: 1px;
  border-color: var(--text-gray-200-color);
  padding: 5px 13px;
  background-color: rgba(var(--brand-01-color), 0.1);
}

.artistName {
  font-family: 'Muli-Regular';
  font-size: 10px;
  line-height: 12px;
  @media (min-width: 640px) {
    font-size: 10px;
    line-height: 12px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }

  letter-spacing: 0;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceName {
  font-family: 'Muli-Regular';
  font-size: 8px;
  line-height: 8px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }

  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color), 0.65);
  white-space: nowrap;
}

.price {
  font-family: 'Muli-Bold';
  font-size: 12px;
  line-height: 14px;

  @media (min-width: 640px) {
    font-size: 13px;
    line-height: 15px;
  }
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (min-width: 1536px) {
    font-size: 18px;
    line-height: 24px;
  }

  letter-spacing: 0;
  color: var(--text-gray-200-color);
}
