@import 'assets/scss/variable.module.scss';

.nftName {
  display: flex;
  justify-content: center;
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
  color: var(--text-gray-200-color);
  cursor: pointer;
  &:hover {
    @media (min-width: 1024px) {
      text-decoration: underline;
    }
  }
}

.actionType {
  display: flex;
  justify-content: center;
}

.userName {
  font-family: 'Muli-Regular';
  font-size: 16px;
  font-weight: 100;
  color: var(--text-gray-200-color);
  cursor: pointer;
  &:hover {
    @media (min-width: 1024px) {
      text-decoration: underline;
    }
  }
}

.text {
  font-family: 'Muli-Regular';
  font-size: 16px;
  font-weight: 100;
  color: var(--text-gray-200-color)
}

.date {
  font-family: 'Muli-Light';
  font-size: 14px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8)
}

.view {
  width: 82px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  color: var(--text-gray-200-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  padding-right: 10px;
}

.headerSection {
  width: 100%;
  height: 70px;
  background: rgba(var(--main-bg-color-rgb), 0.05);
  padding: 0px 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.7);
}

.row {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  background: var(--background-main-color);
  padding: 0px 30px 0px 15px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.border {
  width: 1px;
  min-width: 1px;
  height: 55px;
  background: rgba(var(--main-bg-color-rgb), 0.25);
}
