@import 'assets/scss/variable.module.scss';

.activeButton {
  width: 100%;
  height: 72px;
  padding-left: 14px;
  padding-right: 14px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: var(--profile-card-bg);
  border: 1px solid var(--activity-border-color);
  border-style: solid solid none solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.button {
  width: 100%;
  height: 72px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 15px;
  background: var(--profile-card-bg);
  box-shadow: 0px 1px 6px var(--activity-box-color);
  border-radius: 12px;
  border: 1px solid var(--activity-default-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--activity-border-color);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-style: none solid solid solid;
  overflow: hidden;
}

.imageContainer {
  width: 54px;
  height: 54px;
  background-color: #707070;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 321:378 Aspect Ratio */
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.activeImageArea {
  width: 54px;
  height: 54px;
  background-color: var(--brand-09-color);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstRow {
  width: 100%;
  height: 80px;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(var(--main-bg-color-rgb), 0.05);
}

.secondRow {
  width: 100%;
  height: 60px;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
}

.title {
  font-family: 'Muli-Bold';
  font-size: 14px;
  color: var(--text-gray-200-color);
}

.text {
  font-family: 'Muli-Regular';
  font-size: 14px;
  color: var(--text-gray-200-color);
}

.date {
  font-family: 'Muli-Light';
  font-size: 12px;
  color: rgba(var(--text-gray-200-color-rgb), 0.9);
}

.chevronUpIcon {
  color: var(--chevron-up-icon-color);
}

.subTitle {
  font-family: 'Muli-Bold';
  font-size: 16px;
  line-height: 20px;
  text-align: start;
  color: var(--text-gray-200-color);
}

.subType {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  text-align: start;
  margin-top: 2px;
}

.userName {
  font-size: 14px;
  font-family: 'Muli-Bold';
  margin-top: -2px;
  color: var(--text-gray-200-color);
  cursor: pointer;
}

.usdValue {
  display: flex;
  font-size: 12px;
  font-family: 'Muli-Light';
  color: var(--text-gray-200-color);
  justify-content: flex-end;
}