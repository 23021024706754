@import 'assets/scss/variable.module.scss';

.activeButton {
  width: 100%;
  height: 72px;
  padding-left: 14px;
  padding-right: 14px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: var(--stats-bg-color);
  border: 1px solid var(--stats-accordion-border);
  border-style: solid solid none solid;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: 100%;
  height: 72px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 15px;
  // background: linear-gradient(to bottom, rgba(var(--brand-02-color), 35%), rgba(var(--background-dark-color), 35%));
  background-color: var(--stats-bg-color);
  border: 1px solid var(--stats-accordion-border);

  // border: 1px solid #292929;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid var(--stats-accordion-border);
  background-color: var(--stats-bg-color);
  overflow: hidden;
}

.imageArea {
  width: 54px;
  height: 54px;
  background-color: #707070;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.firstRow {
  width: 100%;
  height: 42px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--background-dark-color), 0.8);
}

.secondRow {
  width: 100%;
  height: 42px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--accordion-second-row-bg);
}

.thirdRow {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding-left: 15px; 
  padding-right: 15px;
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 18px;
  color: var(--text-gray-200-color);
}

.subTitle {
  font-family: 'Muli-Bold';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
}

.subDesc {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  margin-top: 6px;
  text-align: start;
}

.amount {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
}

.text {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.help {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}
