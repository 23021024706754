@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataTitle {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 28px;
  color: var(--text-gray-200-color);
  text-align: center;
}

.noDataDesc {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 24px;
  @media (min-width: 1024px) {
    font-size: 16px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  text-align: center;
  word-break: break-word;
}
