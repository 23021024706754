@import 'assets/scss/variable.module.scss';

.ringLoader {
  display: flex;
  justify-content: center;
}

.ringLoader > span > span {
  opacity: 1 !important;
}

.loadingText {
  font-family: 'Muli-Regular';
  font-size: 18px;
  font-weight: 100;
  line-height: 23px;
  color: var(--text-gray-200-color);
}