@import 'assets/scss/variable.module.scss';

.container {
  width: auto;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  border-bottom: 3px solid transparent;
  &:focus {
    outline: none;
  }
  font-size: 18px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 15px;
    margin-left: 22px;
    margin-right: 22px;
  }
  // margin-bottom: 20px;
  white-space: nowrap;
}

.activeTab {
  font-family: 'Muli-SemiBold';
  border-bottom: 3px solid var(--main-icon-color);
  color: var(--text-gray-200-color);
}
.inactiveTab {
  font-family: 'Muli-Regular';
  color: rgba(var(--text-gray-200-color-rgb), 0.6);
}
