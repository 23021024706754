@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  margin-top: 16px;
  @media (min-width: 1024px) {
    margin-top: 30px;
  }
  width: 100%;
}

.comingSoon {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 23px;
  margin-top: 40px;
  @media (min-width: 1024px) {
    margin-top: 70px;
  }
  color: var(--text-gray-200-color);
  text-align: center;
}
