@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  margin-top: 16px;
  @media (min-width: 1024px) {
    margin-top: 52px;
  }
  width: 100%;
}

.bottomLeftEllipse {
  position: absolute;
  top: -500px;
  right: -760px;
  z-index: 0;
  opacity: 0.25;
}

.title {
  font-family: 'Muli-Bold';
  font-size: 24px;
  line-height: 36px;
  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (min-width: 1280px) {
    font-size: 40px;
    line-height: 56px;
  }
  color: var(--text-gray-200-color);
}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  font-family: 'Muli-SemiBold';
  font-size: 24px;
  line-height: 36px;
  color: var(--text-gray-200-color);
}
