$brand-01-color: #5ac3ba;
$brand-02-color: #378287;
$brand-03-color: #63d6cd;
$brand-04-color: #1b1b1b;
$brand-05-color: #707070;
$brand-06-color: #deefd0;
$brand-09-color: #95dbd5;
$brand-10-color: #2d625d;
$brand-11-color: #9cedd6;
$brand-12-color: #4b6e6b;

$background-black-color: #121212;
$background-dark-color: #202427;
$background-light-color: #eff0f6;

$error-color: #a21f23;
$success-color: #5ac3ba;

$text-gray-800-color: #202427;
$text-gray-400-color: rgba(#202427, 0.6);
$text-gray-200-color: #eff0f6;

$white-color: #ffffff;
$black-color: #000000;
