@import 'assets/scss/variable.module.scss';

.radio {
  appearance: none;
  border: 2px solid rgba(var(--text-gray-200-color-rgb), 0.6);
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  &:checked {
    border: 2px solid var(--radio-check-color);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: var(--radio-check-color);
    }
  }
  &:focus {
    outline: none;
  }
}

.lightRadio {
  appearance: none;
  border: 2px solid rgba(var(--background-dark-color), 0.6);
  background-color: transparent;
  vertical-align: top;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  &:checked {
    border: 2px solid var(--background-dark-color);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: var(--background-dark-color);
    }
  }
  &:focus {
    outline: none;
  }
}

.label {
  flex: 1;
  cursor: pointer;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 25px;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  text-wrap: nowrap;
  letter-spacing: 0;
}
.label:hover {
  color: rgba(var(--text-gray-200-color-rgb), 1);
}

.selected {
  color: rgba(var(--text-gray-200-color-rgb), 1) !important;
}
