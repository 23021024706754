@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 45px;
  padding-left: 15px;
  padding-right: 0px;
  border-radius: 12px;
  // background: rgba(var(--main-bg-color-rgb), 0.05);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  @media (min-width: 414px) {
    width: 250px;
    padding-left: 10px;
  }
  @media (min-width: 1024px) {
  justify-content: center;
    width: 100%;
    height: 50px;
    max-width: 490px;
    min-width: 200px;
    padding-left: 20px;
    padding-right: 15px;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.inputArea {
  margin-left: 10px;
  @media (min-width: 1024px) {
    margin-left: 20px;
    flex: 1;
  }
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
  background-color: transparent;
  outline: none;
}

.inputArea::placeholder {
  font-family: 'Muli-Light';
  color: rgba(var(--text-gray-200-color-rgb), 0.6);
}
