@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  margin-top: 16px;
  @media (min-width: 1024px) {
    margin-top: 30px;
  }
  width: 100%;
}

.slotBG {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: var(--time-slot-bg);
  padding: 0px 6px;
  z-index: 10;
}