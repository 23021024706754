@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  @media (min-width: 640px) {
    width: 100%;
  }
  height: auto;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.hideBtnArea {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  @media (min-width: 1024px) {
    left: 20px;
    top: 20px;
    width: 52px;
    height: 52px;
    border-radius: 26px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(239, 240, 247, 0.15);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}

.hideConfirm {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-radius: 8px;
  background: rgba(#1a1a1a, 0.75);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 100;
}

.hideText {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 16px;
  padding: 0px 20px;
  color: $text-gray-200-color;
  text-align: center;
}

.hideCancelButton {
  width: 106px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba($text-gray-200-color, 0.1);
  background: transparent;
  font-family: 'Muli-SemiBold';

  color: $text-gray-200-color;
  cursor: pointer;
}

.hideButton {
  width: 67px;
  height: 26px;
  border-radius: 6px;
  @media (min-width: 640px) {
    border-radius: 8px;
    width: 106px;
    height: 40px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brand-09-color;
  box-shadow: 0px 3px 6px #121212;
  cursor: pointer;
}

.hideButtonText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  color: $background-dark-color;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.topSection {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.bottomSection {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding: 0px 13px;
}

.bottomBtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 20px 23px 20px;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(#1a1a1a, 0.6);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-radius: 10px;
}

.moreBtn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background: var(--second-home-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.closeBtn {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  background: var(--bg-242424-EFF0F605);
  position: absolute;
  top: 22px;
  right: 25px;
}

.imageArea {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.imageInfoHome {
  width: 100%;
  position: relative;
  padding: 9px 12px 10px 12px;
  @media (min-width: 640px) {
    padding: 15px 20px;
  }
  background: transparent;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.imageInfo {
  position: relative;
  width: 100%;
  background: var(--background-main-color);
  padding: 9px 12px 10px 12px;
  @media (min-width: 640px) {
    padding: 15px 20px;
  }
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.imageContent {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: rgba(var(--main-bg-color-rgb), 0.05);
}

.imageNft {
  width: 100%;
  height: 100%;
  max-width: 400px;
  object-fit: cover;
  margin: 0 auto;
  transition: transform 0.2s;
  @media (min-width: 1024px) {
    max-width: 600px;
  }
  &:hover {
    @media (min-width: 1024px) {
      -ms-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.playButton {
  position: absolute;
  z-index: 11;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--background-light-color), 0.2);
  backdrop-filter: blur(10px);
}

.nftNameForHome {
  font-family: 'Muli-Regular';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  color: var(--text-gray-200-color);
}

.nftName {
  font-family: 'Muli-Regular';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  color: var(--text-gray-200-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.artistNameHome {
  font-family: 'Muli-Light';
  font-size: 10px;
  line-height: 13px;
  @media (min-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
  letter-spacing: 0;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.artistName {
  font-family: 'Muli-Light';
  font-size: 14px;
  font-weight: 100;
  margin-top: 7px;
  color: var(--main-icon-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceHome {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  line-height: 23px;
  margin-left: 7px;
  margin-top: -2px;
  @media (min-width: 640px) {
    margin-top: -2px;
    margin-left: 7px;
    font-size: 18px;
    line-height: 24px;
  }
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.price {
  font-family: 'Muli-SemiBold';
  font-size: 12px;
  line-height: 16px;
  margin-left: 3px;
  margin-top: -3px;
  @media (min-width: 640px) {
    margin-top: -3px;
    margin-left: 5px;
    font-size: 18px;
    line-height: 22px;
  }
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

