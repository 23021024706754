@import 'assets/scss/variable.module.scss';
.nftName {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
  display: flex;
  justify-items: center;
  cursor: pointer;
  &:hover {
    @media (min-width: 1024px) {
      text-decoration: underline; 
    }
  }
}

.date {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  margin-top: 11px;
}

.row {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-main-color);
  border-radius: 10px;
  padding: 0px 30px 0px 15px;
  margin-bottom: 20px;
}

.button {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  color: var(--text-gray-200-color);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.headerSection {
  width: 100%;
  height: 70px;
  background: rgba(var(--main-bg-color-rgb), 0.05);
  padding: 0px 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.7);
}

.border {
  width: 1px;
  min-width: 1px;
  height: 55px;
  background: rgba(var(--main-bg-color-rgb), 0.25);
}